import React, { useRef, useState } from 'react';
import CustomInput from '../../components/CustomInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEquals } from '@fortawesome/free-solid-svg-icons';
import FileUpload from '../../components/FileUpload';
import { useAccount, useContractWrite } from 'wagmi';
import { useWaitForTransaction } from 'wagmi';
import toast from 'react-hot-toast';
import { ethers } from 'ethers';
import { waitForTransaction } from '@wagmi/core';
import axios from 'axios';

import { FACTORY } from '../../abis/addresses';
import FactoryABI from '../../abis/factoryABI.json';

const CreateToken = () => {
    const fileInputRef = useRef(null);

    const { address } = useAccount();

    const [image, setImage] = useState();

    const handleImageSelect = (imageURL) => {
        console.log('Selected image URL:', imageURL);
    };


    const handleFormClick = (event) => {
        const fileInputWrapper = document.querySelector('.file-input-wrapper');
        if (!fileInputWrapper.contains(event.target) && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleButtonClick = (event) => {
        // Prevent the form click event from propagating to the parent div
        event.stopPropagation();
        // You can handle the button click logic here
    };

    const [name, setName] = useState('');
    const [ticker, setTicker] = useState('');
    const [description, setDescription] = useState('');

    const [website, setWebsite] = useState('');
    const [twitter, setTwitter] = useState('');
    const [telegram, setTelegram] = useState('');

    const [ethAmount, setEthAmount] = useState('');

    const handleContractWrite = async (contractWrite) => {
      try {
        const data = await contractWrite();
  
        try {
          const tx = await waitForTransaction({ hash: data.hash });
          const CA = tx.logs[0].address;

          const form = new FormData();

          form.append('name', name); form.append('ticker', ticker); form.append('description', description); 
          form.append('deployerAddr', address); form.append('address', CA);
          form.append('telegramUrl', telegram); form.append('twitterUrl', twitter); form.append('websiteUrl', website);
          form.append('file', image);

          const tokenInfo = await axios.post('https://evterminal-backend.vercel.app/v1/token', form);

          //console.log(tokenInfo);
          toast.success("Created Token Successfully", {
            style: {
              border: "1px solid #713200",
              padding: "16px",
              color: "#00CD22",
            },
            position: "top-right",
            duration: 5000,
          });
        } catch (error) {
          toast.error(error.message, {
            style: {
              border: "1px solid #713200",
              padding: "16px",
              color: "#FF0000",
            },
            position: "top-right",
            duration: 5000,
          });
        }
      } catch (error) {
        toast.error(error.message, {
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#FF0000",
          },
          position: "top-right",
          duration: 5000,
        });
      }
    };
  
    const { writeAsync: create } = useContractWrite({
      address: FACTORY,
      abi: FactoryABI,
      functionName: "createERC314",
      args: [name, ticker, 100],
      value: ethers.parseEther((Number(0.02 + 0.02 * Math.floor(ethAmount))).toString()),
    });

    return (
        <div className="w-full flex flex-col items-center justify-center 2xl:py-10 py-4 font-worksans" onClick={handleFormClick}>
            <div className='font-zendots text-[30px] page-title-gradient'>CREATE TOKEN</div>
            <div className='w-full flex space-x-8 2xl:pt-6 pt-4 xl:px-28 lg:px-8 px-4'>
                <div className='w-full md:w-3/5 border-[2px] border-[#292929] bg-[#12171D] md:rounded-tl-[40px] md:rounded-br-[10px] rounded-[10px] px-12'>
                    <div className='flex space-x-6 justify-center py-3'>
                        <CustomInput
                            label="Token Name"
                            placeholder="Enter name"
                            setter={setName}
                            paddingX='px-2'
                            paddingY='2xl:py-4 py-3'
                        />
                        <CustomInput
                            label="Token Ticker"
                            placeholder="Enter name"
                            setter={setTicker}
                            paddingX='px-2'
                            paddingY='2xl:py-4 py-3'
                        />
                    </div>
                    <div className='w-full flex space-x-6 justify-center py-3'>
                        <div className='w-1/2 space-y-4'>
                            <CustomInput
                                label="Description"
                                placeholder="Enter Description"
                                setter={setDescription}
                                paddingX='px-2'
                                paddingY='2xl:py-4 py-3'
                            />
                            <CustomInput
                                label="Website (Optional)"
                                placeholder="Enter Link"
                                setter={setWebsite}
                                paddingX='px-2'
                                paddingY='2xl:py-4 py-3'
                            />
                        </div>
                        <div className='w-1/2 space-y-1'>
                            <div className='font-worksans text-[16px] text-[#A0A2A5]'>Image</div>
                            <FileUpload onImageSelect={handleImageSelect} setImage={setImage}/>
                        </div>
                    </div>
                    <div className='flex space-x-6 justify-center py-3'>
                        <CustomInput
                            label="Twitter (Optional)"
                            placeholder="Enter Link"
                            setter={setTwitter}
                            paddingX='px-2'
                            paddingY='2xl:py-4 py-3'
                        />
                        <CustomInput
                            label="Telegram (Optional)"
                            placeholder="Enter Link"
                            setter={setTelegram}
                            paddingX='px-2'
                            paddingY='2xl:py-4 py-3'
                        />
                    </div>
                    <div className='flex lg:space-x-6 space-x-2 justify-center items-end py-3'>
                        <CustomInput
                            label="Dev Supply (0 - 100%)"
                            placeholder="Enter An Integer from 0-100"
                            setter={setEthAmount}
                            paddingX='px-2'
                            paddingY='2xl:py-4 py-3'
                        />
                        
                    </div>
                    <div className='flex justify-center pt-6 pb-2 w-full'>
                        <button
                            className='font-zendots text-[15px] bg-[#0385FF] rounded-[8px] py-3 text-black font-bold md:w-1/2 lg:w-1/2 xl:w-1/2 my-2 hover:opacity-70 w-full'
                            onClick={() => handleContractWrite(create)}
                        >
                            Create Token
                        </button>
                    </div>
                </div>
                <div className='md:w-2/5 hidden md:block border-[2px] border-[#292929] bg-[#12171D] rounded-tr-[40px] rounded-bl-[10px] text-center py-8 flex flex-col justify-between'>
                    <div className='font-zendots text-[30px] item-title-gradient'>TIPS</div>
                    <div className='font-worksans 2xl:text-[25px] text-[20px] pb-24 text-start px-10'>
                        1. Fill in name and ticker<br />
                        2. Fill in images and socials<br />
                        3. Fill in the % dev supply you want to purchase<br />
                        4. Note that for 1% dev supply, 0.02 eth is charged <br />
                        5. All ETH from dev will be instantly added to the rest of tokens in the liquidity pool<br />
                        6. There is a 0.01 eth deployment fee.
                    </div>
                    {/* <div className='font-zendots text-[18px] underline'>VIEW DOC</div> */}
                </div>
            </div>
        </div>
    );
};

export default CreateToken;
