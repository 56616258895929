import React, { useEffect, useState } from 'react';
import SearchInput from '../../components/SearchBar/SearchInput';
import TokenProfile from '../../components/TokenProfile';
import SwapBoard from '../../components/SwapBoard';
import SwapChatView from './SwapChatView';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const SwapToken = () => {


  const { CA } = useParams();

  const [token, setToken] = useState();

  const [loading, setLoading] = useState(true);

  const [searchAddress, setSearchAddress] = useState(CA);
  
  const getToken = async (CA) => {
    try {
      let res = (await axios.get(`https://evterminal-backend.vercel.app/v1/token/${CA}`));
      if (res.status == 200) {
        setToken(res.data);
      } else {
        setToken(null);
      }
    } catch (e) {
      setToken(null);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (searchAddress == '') {
      setToken(null);
    } else {
      getToken(searchAddress);
    }
  }, [searchAddress])

    return (
        <div className="w-full flex flex-col items-center justify-center space-x-2 px-10 2xl:py-2 py-0 font-worksans">
            <div className='md:w-2/3 w-full space-x-2 flex justify-center 2xl:py-4 py-2'><SearchInput searchAddress={searchAddress} setSearchAddress={setSearchAddress}/></div>
            <div className='md:flex md:justify-between md:space-x-8 py-4'>
                <div className='md:pl-10 pl-0 flex justify-center'>
                    {loading ? <></> :<TokenProfile 
                      name={(token ? token.name : 'NO TOKEN')}
                      ticker={token ? token.ticker : ''}
                      url={token ? token.url : 'NO TOKEN'}
                      description={token ? token.description : ''}
                      tokenUrl={token ? token.logoUrl : '/images/ethereum.png'}
                      CA={searchAddress}
                      telegramUrl={token ? token.telegramUrl : ''}
                      twitterUrl={token ? token.twitterUrl : ''}
                      websiteUrl={token ? token.websiteUrl : ''}
                    />}
                </div>
                <div className='flex items-center justify-center'>
                    {loading ? <></> : <SwapBoard
                      name={token ? token.name : 'NO TOKEN'}
                      ticker={token ? token.ticker : ''} 
                      CA={searchAddress}
                    />}
                </div>
                {/* <div className='pr-10 lg:block hidden'>
                    <SwapChatView />
                </div> */}
            </div>
        </div>
    );
};

export default SwapToken;
